import React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/index.css";

import { HelmetBlock } from "../components/HelmetBlock";
import { Header } from "../components/HeaderBlock";
import { Footer } from "../components/FooterBlock";
import { CookieBanner } from "../components/CookieBanner";
import styled from "styled-components";

const Document = ({ data }) => {
  const pageId = data.wpPage.databaseId;

  // Adding specific page class
  const pageCondicionesDeUso = pageId === 72;
  const pageAvisoLegal = pageId === 110;
  const documentClass = pageCondicionesDeUso
    ? "condiciones-de-uso"
    : pageAvisoLegal
    ? "aviso-legal"
    : "other-page";

  return (
    <>
      <HelmetBlock data={data.wpPage} />
      <Header data={data.wpPage} />
      <DocumentBlock className={documentClass}>
        <Container>
          <h1>{data.wpPage.title}</h1>
          <div
            className={"content"}
            dangerouslySetInnerHTML={{
              __html: data.wpPage.content,
            }}
          />
        </Container>
      </DocumentBlock>
      <Footer />
      <CookieBanner />
    </>
  );
};

export default Document;

const DocumentBlock = styled.div`
  padding: 10px 0 50px;

  @media (min-width: 768px) {
    padding: 80px 0 100px;
  }

  h1 {
    margin-bottom: 15px;
    color: #378aff;
    font-size: 16px;
    font-weight: 700;

    @media (min-width: 768px) {
      margin-bottom: 50px;
      font-size: 28px;
    }
  }

  h2 {
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  p,
  h3 {
    font-size: 14px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 18px;
      margin-bottom: 50px;
    }
  }

  h3 {
    margin-bottom: 0;
    font-weight: 400;
  }

  p:last-child {
    margin-bottom: 0;
  }

  p + ul,
  p + ol {
    margin-top: -15px;

    @media (min-width: 768px) {
      margin-top: -40px;
    }
  }

  ul,
  ol {
    padding: 0;
    margin-bottom: 20px;
    list-style-position: inside;

    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
  }

  ol.roman-numerals {
    list-style-type: lower-roman;
  }

  &.condiciones-de-uso {
    counter-reset: headings;

    h2 {
      counter-increment: headings;

      &::before {
        content: counter(headings) ". ";
      }
    }
  }

  &.aviso-legal {
    counter-reset: headings;

    h2 {
      counter-increment: headings;

      &::before {
        content: counter(headings) ". ";
      }
    }

    ol {
      counter-reset: list;
      list-style: none;

      li {
        counter-increment: list;
        font-size: 14px;

        @media (min-width: 768px) {
          font-size: 18px;
        }

        &::before {
          content: counter(headings) "." counters(list, ".") ". ";
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      databaseId
      parentDatabaseId
      seo {
        title
        metaDesc
      }
    }
  }
`;
